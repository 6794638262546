<template>
  <section class="inventions" :class="[{ preview: previewMode }, viewMode]">
    <left-panel
      v-if="!previewMode"
      :view-mode="viewMode"
      :sort="sort"
      :can-load-more="hasMore"
      @sorting-changed="onSortChange"
      @change-view="changeView"
      @load-more="loadMore"
    />
    <content-area :view-mode="viewMode" />
  </section>
</template>

<script>
import { mapState } from 'vuex';

import LeftPanel from './pageParts/LeftPanel.vue';
import InventionContent from './InventionContent.vue';
export default {
  components: {
    LeftPanel,
    ContentArea: InventionContent
  },
  data() {
    return {
      isRefreshing: false,
      timeoutRef: null,
      viewMode: 'composite',
      sort: 'next',
      storeSubscription: null,
      hasMore: true,
      pageSize: 20
    };
  },
  computed: {
    ...mapState({
      previewMode: s => s.previewMode,
      collection: s => s.reports.myInventions.data
    })
  },
  async beforeUnmount() {
    clearTimeout(this.timeoutRef);
    this.storeSubscription && this.storeSubscription();
  },
  async created() {
    await Promise.all([this.$store.dispatch('reports/myInventions/getCollection', { size: 20 }), this.$store.dispatch('inventions/my')]);
    if (this.collection.length < 20) {
      this.hasMore = false;
    }

    this.executeRefreshTimeout();
    this.storeSubscription = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'reports/myInventions/taskFetched') {
        const updatedInvention = this.collection.find(
          invention =>
            (invention.nextTask && invention.nextTask.id === mutation.payload.task.id) ||
            (invention.nextCriticalTask && invention.nextCriticalTask.id === mutation.payload.task.id)
        );

        if (updatedInvention) {
          this.refresh();
        }
      }
    });
  },
  methods: {
    executeRefreshTimeout() {
      this.timeoutRef = setTimeout(async () => {
        if (!this.isRefreshing) {
          this.isRefreshing = true;
          await this.refresh();
          this.isRefreshing = false;

          this.executeRefreshTimeout();
        }
      }, 60 * 1000);
    },
    changeView() {
      if (this.viewMode === 'composite') {
        this.viewMode = 'detailed';
      } else {
        this.viewMode = 'composite';
      }
    },
    async refresh() {
      let size =
        this.collection.length % this.pageSize !== 0
          ? Math.floor(this.collection.length / this.pageSize + 1) * this.pageSize
          : this.collection.length;

      size = size < this.pageSize ? this.pageSize : size;

      await this.$store.dispatch('reports/myInventions/getCollection', { size, refresh: true, sort: this.sort });
    },
    async onSortChange(sort) {
      this.sort = sort;
      await this.refresh();
    },
    async loadMore() {
      const prevLength = this.collection.length;
      await this.$store.dispatch('reports/myInventions/getCollection', { size: this.pageSize, sort: this.sort, skip: this.collection.length });
      this.hasMore = this.collection.length > prevLength;
    }
  }
};
</script>

<style lang="scss" scoped>
.splitter {
  height: 100%;
  background: var(--theme-surface);
  align-self: center;
  margin: 1px;
}

.splitter-detailed {
  cursor: w-resize;
}

.splitter-composite {
  cursor: e-resize;
}

.composite {
  grid-template-columns: minmax(0, 3fr) minmax(0, 8fr);
}
.detailed {
  grid-template-columns: 1fr 1fr;
}

.inventions {
  display: grid;
  grid-template-rows: minmax(0, 1fr);
  grid-gap: 2px;
  height: 100%;
  width: 100%;
  .expand-button {
    background: var(--theme-surface);
  }
  &.preview {
    grid-template-columns: 1fr;
  }
  .notification {
    display: grid;
    justify-content: center;
    align-content: center;
  }
}
</style>
